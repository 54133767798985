import { Maybe, SanityPricePlan } from "@graphql-types";
import { TABLET_BREAKPOINT, colors } from "@util/constants";
import { generateId } from "@util/text";
import React from "react";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";
import { Button } from "./Button";
import ProductPrice from "./ProductPrice";

export const GridItem = styled("div")<{ gridColumn: number; gridRow: number }>`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  padding: 10px;
  grid-column: ${({ gridColumn }) => `${gridColumn}`};
  grid-row: ${({ gridRow }) => `${gridRow}`};

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    grid-column: 1;
    grid-row: auto;
    spacing: 5px;
    ${({ gridColumn, gridRow }) =>
      gridColumn !== 1 && gridRow === 1 && "margin-top: 40px; "}
  }
`;

const Checkmark = styled(FaCheck)`
  width: 20px;
  height: 20px;
  color: ${colors.lightBlue};
  align-self: center;
`;

const DesktopButton = styled(Button)`
  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileButton = styled(Button)<{}>`
  @media screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

interface PricingListProps {
  pricingList: Maybe<SanityPricePlan>;
  column: number;
  isPink: boolean;
  cellCount: number;
}

const PricingList = ({
  pricingList,
  column,
  isPink,
  cellCount,
}: PricingListProps) => {
  if (!pricingList) return <p>This pricing list could not be retrieved!</p>;

  const featuresList =
    pricingList.featureList?.map((f, ind) => (
      <GridItem
        key={f}
        gridColumn={column}
        gridRow={ind + 7}
        style={{
          borderRadius: ind === cellCount - 1 ? "0 0 20px 20px" : "0px",
          paddingInline: "35px",
          paddingBottom: ind === cellCount - 1 ? "35px" : "0px",
          paddingTop: "20px",
        }}
      >
        <div style={{ display: "flex", paddingRight: "10px" }}>
          <Checkmark />
        </div>
        <p style={{ padding: 0, margin: 0, alignSelf: "center" }}>{f}</p>
      </GridItem>
    )) ?? [];

  for (let i = pricingList?.featureList?.length ?? 0; i < cellCount; i++) {
    featuresList.push(
      <GridItem
        key={i}
        gridColumn={column}
        gridRow={i + 7}
        style={{
          borderRadius: i === cellCount - 1 ? "0 0 20px 20px" : "0px",
          paddingInline: "35px",
          paddingBottom: i === cellCount - 1 ? "35px" : "0px",
        }}
      ></GridItem>
    );
  }

  return (
    <>
      <GridItem
        gridColumn={column}
        gridRow={1}
        style={{
          padding: "35px 35px 0 35px",
          backgroundColor: "white",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <h3>{pricingList.title?.toLocaleUpperCase()}</h3>
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={2}
        style={{
          paddingTop: 0,
          backgroundColor: "white",
          paddingInline: "35px",
        }}
      >
        <p style={{ fontSize: "12pt", margin: 0 }}>{pricingList.description}</p>
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={3}
        style={{
          paddingTop: 0,
          backgroundColor: "white",
          paddingInline: "35px",
        }}
      >
        {pricingList.costDescription ? (
          <p>{pricingList.costDescription}</p>
        ) : (
          pricingList.currency &&
          pricingList.pricePerBillingInterval &&
          pricingList.unit &&
          pricingList.perUnitSuffix && (
            <ProductPrice
              currency={pricingList.currency}
              itemPrice={pricingList.pricePerBillingInterval}
              unit={pricingList.unit}
              billingInterval={pricingList.billingInterval}
              perUnitSuffix={pricingList.perUnitSuffix}
            />
          )
        )}
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={4}
        style={{
          paddingTop: 0,
          backgroundColor: "white",
          paddingInline: "35px",
        }}
      >
        {pricingList.currency &&
          pricingList.annualPrice &&
          pricingList.unit &&
          pricingList.annualPriceBillingInterval &&
          pricingList.perUnitSuffix && (
            <ProductPrice
              currency={pricingList.currency}
              itemPrice={pricingList.annualPrice}
              unit={pricingList.unit}
              billingInterval={pricingList.annualPriceBillingInterval}
              perUnitSuffix={pricingList.perUnitSuffix}
              small
            />
          )}
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={5}
        style={{ backgroundColor: "white", paddingInline: "35px" }}
      >
        <DesktopButton
          id={
            pricingList.pricingButton?.elementId
              ? generateId([pricingList.pricingButton?.elementId])
              : generateId([
                  pricingList.title,
                  "pricing",
                  "plan",
                  pricingList.pricingButton?.linkText,
                  "button",
                ])
          }
          isPink={isPink}
          url={pricingList.pricingButton?.linkUrl}
          linkText={pricingList.pricingButton?.linkText}
        />
        <MobileButton
          id={
            pricingList.pricingButton?.elementId
              ? generateId([pricingList.pricingButton?.elementId])
              : generateId([
                  pricingList.title,
                  "pricing",
                  "plan",
                  pricingList.pricingButton?.mobileLinkText,
                  "button",
                ])
          }
          isPink={isPink}
          url={pricingList.pricingButton?.mobileLinkURL}
          linkText={pricingList.pricingButton?.mobileLinkText}
        />
      </GridItem>
      <GridItem
        gridColumn={column}
        gridRow={6}
        style={{ backgroundColor: "white", paddingInline: "35px" }}
      >
        <b>{pricingList.listHeader == null ? "" : pricingList.listHeader}</b>
      </GridItem>
      {featuresList}
    </>
  );
};

export { PricingList };
