import { Maybe, SanityPricePlan } from "@graphql-types";
import { MAX_WIDTH, TABLET_BREAKPOINT } from "@util/constants";
import React from "react";
import styled from "styled-components";
import { PricingList } from "./PricingList";



const PricingPaper = styled("div")`
  background-color: "#fff";
  position: relative;
  max-width: ${MAX_WIDTH}px;
`;

//TODO: Move this definition somewhere else
export const Grid = styled("div")<{ numCols: number; gap?: number }>`
  display: grid;

  grid-template-columns: repeat(${({ numCols }) => numCols}, minmax(0, 1fr));
  grid-template-rows: 1fr;
  ${({ gap }) => `gap: ${gap ?? 10}px;`}
  width: fit-content;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    spacing: 5px;
  }
`;

const PricingGrid = styled(Grid)<{ numCols: number; gap?: number }>`
  & > *:nth-child(n + 5) {
    background-color: white;
  }

  column-gap: 20px;
`;

interface PricingListsProps {
  sanityPricingLists: Maybe<Maybe<SanityPricePlan>[]> | undefined;
}

const PricingLists = ({ sanityPricingLists }: PricingListsProps) => {
  if (!sanityPricingLists)
    return (
      <div>
        <p>Pricing lists could not be retrieved!</p>
      </div>
    );

  const cellCount =
    sanityPricingLists
      .map(spl => spl?.featureList?.length)
      .reduce((prev, next) => Math.max(prev ?? 0, next ?? 0)) ?? 0;

  return (
    <PricingPaper>
      <PricingGrid numCols={3} gap={0}>
        {sanityPricingLists.map((spl, ind) => (
          <PricingList
            key={spl?._key}
            pricingList={spl}
            column={ind + 1}
            isPink={ind === sanityPricingLists.length - 1}
            cellCount={cellCount}
          />
        ))}
      </PricingGrid>
    </PricingPaper>
  );
};

export { PricingLists };
export type { PricingListsProps };
