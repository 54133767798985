import { Container } from "@util/standard";
import React from "react";

interface ProductPriceProps {
  currency: string;
  itemPrice: string;
  unit: string;
  billingInterval?: string | null;
  perUnitSuffix: string;
  small?: boolean;
}

export default function ProductPrice({
  currency,
  itemPrice,
  unit,
  billingInterval,
  perUnitSuffix,
  small,
}: ProductPriceProps) {
  return (
    <Container>
      <span
        style={{
          fontSize: small ? "12pt" : "24pt",
          margin: 0,
          fontWeight: "bold",
          fontFamily: "header",
          lineHeight: "normal",
        }}
      >{`${itemPrice} ${currency}`}</span>
      <span
        style={{
          fontSize: "11pt",
          margin: 0,
          marginLeft: small ? "5px" : "10px",
          fontFamily: "header",
        }}
      >{`/ ${unit} ${perUnitSuffix}`}</span>
      {billingInterval && (
        <p
          style={{
            fontSize: small ? "11pt" : "12pt",
            margin: 0,
            fontFamily: "header",
          }}
        >{`${small ? "billed" : "Billed"} ${billingInterval}`}</p>
      )}
    </Container>
  );
}
